import { auth, firestore as db } from '@jaymathew/ui-shared/apis/FirebaseUtilities/FirebaseConfig'
import { encodeAndTokenizeForDB, encodeAndTokenizeForQueries } from '@jaymathew/utils/Search'
import {
	collection, deleteDoc, doc, DocumentData, getDoc, getDocs, limit, orderBy, query, QueryDocumentSnapshot, setDoc, startAfter, Timestamp, where
} from 'firebase/firestore'
import {
	Asset, Deal, DealDetail, Tag, User
} from '../resources/types'

export const getEndUserInfo = async () => {
	const user = auth.currentUser

	const userDocRef = doc(db, 'users', user!.uid)
	const userData = await getDoc(userDocRef)

	if (!userData.exists) {
		throw new Error('The supplied user id did not match any user.')
	}

	return userData.data() as User
}

export const getTags = async (limitNumber = 10, searchTerm?: string, lastDocument?: QueryDocumentSnapshot<DocumentData>) => {
	const collectionRef = collection(db, 'tags')
	let tagsCollectionRef

	if (!searchTerm || searchTerm === '') {
		tagsCollectionRef = query(collectionRef, orderBy('name', 'asc'))
	} else {
		const encodedSearchTerm = await encodeAndTokenizeForQueries(searchTerm)
		tagsCollectionRef = query(collectionRef, where('searchTerms', 'array-contains-any', encodedSearchTerm), orderBy('name', 'asc'))
	}

	if (lastDocument) {
		tagsCollectionRef = query(tagsCollectionRef, startAfter(lastDocument))
	}

	tagsCollectionRef = query(tagsCollectionRef, limit(limitNumber))
	const tagCollection = await getDocs(tagsCollectionRef)

	if (tagCollection.empty) {
		return []
	}

	const tags = tagCollection.docs.map((innerDoc) => innerDoc.data() as Tag)

	return [tags, tagCollection.docs[tagCollection.docs.length - 1]] as [Tag[], QueryDocumentSnapshot<DocumentData>]
}

export const getDeals = async (sortBy: keyof Deal, sort: 'asc' | 'desc' = 'asc', limitNumber = 20, searchTerm?: string, lastDocument?: QueryDocumentSnapshot<DocumentData>) => {
	const collectionRef = collection(db, 'deals')
	let dealsCollectionRef

	if (!searchTerm || searchTerm === '') {
		dealsCollectionRef = query(collectionRef, orderBy(sortBy.toString(), sort))
	} else {
		const encodedSearchTerm = await encodeAndTokenizeForQueries(searchTerm)
		dealsCollectionRef = query(collectionRef, where('searchTerms', 'array-contains-any', encodedSearchTerm), orderBy(sortBy.toString(), sort))
	}

	if (lastDocument) {
		dealsCollectionRef = query(dealsCollectionRef, startAfter(lastDocument))
	}

	dealsCollectionRef = query(dealsCollectionRef, limit(limitNumber))
	const dealsCollection = await getDocs(dealsCollectionRef)

	if (dealsCollection.empty) {
		return []
	}

	const deals: Deal[] = dealsCollection.docs.map((innerDoc) => innerDoc.data())
	return [deals, dealsCollection.docs[dealsCollection.docs.length - 1]] as [Deal[], QueryDocumentSnapshot<DocumentData>]
}

export const getDealDetail = async (dealID: string) => {
	const dealDocRef = doc(db, 'deals', dealID, 'detail', 'detail')
	const dealDetailData = await getDoc(dealDocRef)

	if (!dealDetailData.exists) {
		return {}
	}

	return dealDetailData.data() as DealDetail
}

export const getAssets = async (sortBy: keyof Deal, sort: 'asc' | 'desc' = 'asc', limitNumber = 20, searchTerm?: string, lastDocument?: QueryDocumentSnapshot<DocumentData>) => {
	const collectionRef = collection(db, 'assets')
	let assetsCollectionRef

	if (!searchTerm || searchTerm === '') {
		assetsCollectionRef = query(collectionRef, orderBy(sortBy.toString(), sort))
	} else {
		const encodedSearchTerm = await encodeAndTokenizeForQueries(searchTerm)
		assetsCollectionRef = query(collectionRef, where('searchTerms', 'array-contains-any', encodedSearchTerm), orderBy(sortBy.toString(), sort))
	}

	if (lastDocument) {
		assetsCollectionRef = query(assetsCollectionRef, startAfter(lastDocument))
	}

	assetsCollectionRef = query(assetsCollectionRef, limit(limitNumber))
	const assetsCollection = await getDocs(assetsCollectionRef)

	if (assetsCollection.empty) {
		return []
	}

	const assets: Asset[] = assetsCollection.docs.map((innerDoc) => innerDoc.data())
	return [assets, assetsCollection.docs[assetsCollection.docs.length - 1]] as [Asset[], QueryDocumentSnapshot<DocumentData>]
}

export const createAsset = async (userUID: string, tags?: string[]) => {
	const assetRef = doc(collection(db, 'assets'))

	const searchTerms = await encodeAndTokenizeForDB(tags || [])

	const newAsset: Asset = {
		nodeName: assetRef.id,
		tags,
		searchTerms,
		createdByID: userUID,
		createdOn: Timestamp.now(),
	}

	await setDoc(assetRef, newAsset)

	return newAsset
}

export const deleteAsset = async (asset: Asset) => deleteDoc(doc(db, 'assets', asset.nodeName!))
