import { auth } from '@jaymathew/ui-shared/apis/FirebaseUtilities/FirebaseConfig'
import {
	browserSessionPersistence,
	onAuthStateChanged,
	setPersistence,
	signInWithEmailAndPassword,
	Unsubscribe
} from 'firebase/auth'
import { useEffect, useState } from 'react'

let unsubscribe: Unsubscribe

const useSignIn = () => {
	const [loading, setLoading] = useState(false)
	const [shouldShowAccountNeedsVerifiedDialog, setShouldShowAccountNeedsVerifiedDialog] = useState(false)
	const [isVerifiedUserSignedIn, setIsVerifiedUserSignedIn] = useState(false)

	useEffect(() => {
		unsubscribe = onAuthStateChanged(auth, (user) => {
			if (user) {
				// User is signed in.
				if (user.emailVerified) {
					setIsVerifiedUserSignedIn(true)
				} else {
					setShouldShowAccountNeedsVerifiedDialog(true)
				}
			} else {
				// User is signed out.
				setIsVerifiedUserSignedIn(false)
			}
		})
		return () => unsubscribe()
	}, [])

	const signIn = async (username: string, password: string) => {
		if (!username || !password) {
			throw new Error('Missing Information, Please enter your email and password before logging in.')
		}

		try {
			setLoading(true)
			await Promise.all([
				setPersistence(auth, browserSessionPersistence),
				signInWithEmailAndPassword(auth, username, password),
			])
		} catch (error) {
			setLoading(false)
			// Handle Errors here.
			const errorCode = (error as {code:string}).code
			const errorMessage = (error as Error).message
			// [START_EXCLUDE]
			if (errorCode === 'auth/wrong-password') {
				throw new Error('The username and/or password does not match our records')
			} else if (errorCode === 'auth/user-not-found') {
				throw new Error('The username does not match our records')
			} else {
				throw new Error(errorMessage)
			}
		}
	}

	const closeVerificationEmailDialog = () => {
		setShouldShowAccountNeedsVerifiedDialog(false)
	}

	return {
		isVerifiedUserSignedIn,
		signIn,
		signInLoading: loading,
		setSignInLOading: setLoading,
		shouldShowAccountNeedsVerifiedDialog,
		closeVerificationEmailDialog,
	}
}

export default useSignIn
