// page URLs
export const loginPage = '/Login'
export const dealsPage = '/Deals'
export const imagePage = '/Images'
export const manageTagsPage = '/ManageTags'
export const manageImagesPage = '/ManageImages'
export const profilePage = '/Profile'

export const toolTipTimeButton = 300
export const toolTipTimeTable = 300
export const toolTipSidebar = 300
export const drawerWidth = 240
export const footerHeight = 70
