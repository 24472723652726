import { Loading } from '@jaymathew/ui-shared/elements/Loading'
import { lazy, Suspense } from 'react'
import { Provider } from 'react-redux'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { PersistGate } from 'redux-persist/integration/react'
import { persistor, store } from 'redux/store'
import * as constants from 'resources/constants'
import LocalThemeWrapper from 'resources/localThemeWrapper'
import LoginPage from 'views/login'
import ScrollToTop from './ScrollToTop'

const RootPage = lazy(() => import('views/root'))
const DealsPage = lazy(() => import('views/deals/index'))
const ProfilePage = lazy(() => import('views/profile'))
const ImagePage = lazy(() => import('views/images'))

const routes = () => (
	<Provider store={store}>
		<PersistGate loading={null} persistor={persistor}>
			<LocalThemeWrapper>
				<BrowserRouter>
					<ScrollToTop>
						<Suspense fallback={<Loading />}>
							<Routes>
								<Route path='/' element={<LoginPage />} />
								<Route path={constants.loginPage} element={<LoginPage />} />
								<Route element={<RootPage />}>
									<Route path={constants.dealsPage} element={<DealsPage />} />
									<Route path={constants.profilePage} element={<ProfilePage />} />
									<Route path={constants.manageImagesPage} element={<ImagePage />} />
								</Route>
							</Routes>
						</Suspense>
					</ScrollToTop>
				</BrowserRouter>
			</LocalThemeWrapper>
		</PersistGate>
	</Provider>
)

export default routes
