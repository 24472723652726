import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface AppSettingsState {
	darkTheme: boolean
}

const initialState: AppSettingsState = {
	darkTheme: window.matchMedia('(prefers-color-scheme: dark)').matches,
}

export const AppSettingsSlice = createSlice({
	name: 'appSettings',
	initialState,
	reducers: {
		showDarkTheme: (state, action: PayloadAction<boolean>) => {
			state.darkTheme = action.payload
		},
	},
})

// Action creators are generated for each case reducer function
export const { showDarkTheme } = AppSettingsSlice.actions

export default AppSettingsSlice.reducer
