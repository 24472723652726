import { resendVerificationEmail } from '@jaymathew/ui-shared/apis/CreateAccount'
import { auth } from '@jaymathew/ui-shared/apis/FirebaseUtilities/FirebaseConfig'
import { BasicDialog } from '@jaymathew/ui-shared/elements/BasicDialog'
import { Div } from '@jaymathew/ui-shared/elements/Div'
import { Snackbar } from '@jaymathew/ui-shared/elements/Snackbar'
import { ResetPasswordDialog } from '@jaymathew/ui-shared/modules/login/ResetPasswordDialog'
import { VerificationNeededDialog } from '@jaymathew/ui-shared/modules/login/VerificationNeededDialog'
import { Paper } from '@mui/material'
import { SxProps, Theme } from '@mui/material/styles'
import { loginAnalytics } from 'api/NetworkManager'
import logo from 'assets/LogoIcon.svg'
import { sendPasswordResetEmail } from 'firebase/auth'
import { useEffect, useState } from 'react'
import { useAppDispatch } from 'redux/hooks'
import { useLocation, useNavigate } from 'react-router-dom'
import { getUserData } from 'redux/userSlice'
import { dealsPage } from 'resources/constants'
import Login from './login'
import useSignin from './useSignIn'

const styles = {
	root: {
		backgroundColor: (theme) => theme.palette.background.default,
		minHeight: '100vh',
		display: 'flex',
		justifyContent: 'center',
		alignItems: { xs: 'stretch', sm: 'center' },
		padding: { xs: 0, sm: 2 },
	} as SxProps<Theme>,

	paper: {
		backgroundColor: 'background.paper',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'flex-start',
		alignItems: 'center',
		width: { xs: '100vw', sm: 460 },
	} as SxProps<Theme>,
}
interface useLocationType {
	redirectURL?: string
}

const LoginPage = () => {
	const navigate = useNavigate()
	const dispatch = useAppDispatch()
	const locationState = useLocation().state as useLocationType

	const {
		signIn, signInLoading, setSignInLOading, shouldShowAccountNeedsVerifiedDialog, closeVerificationEmailDialog, isVerifiedUserSignedIn,
	} = useSignin()

	const [shouldShowforgotPasswordDialog, setShouldShowforgotPasswordDialog] = useState(false)
	const [errorObject, setErrorObject] = useState({ title: '', message: '', shouldShow: false })
	const [snackBarObject, setSnackBarObject] = useState({ shoudlShow: false, message: '' })

	useEffect(() => {
		if (isVerifiedUserSignedIn) {
			navToApp()
		}
	}, [isVerifiedUserSignedIn])

	const showforgotPasswordDialog = () => {
		setShouldShowforgotPasswordDialog(true)
	}

	const closeDialogs = () => {
		setShouldShowforgotPasswordDialog(false)
		setErrorObject({ title: '', message: '', shouldShow: false })
		setSnackBarObject({ shoudlShow: false, message: '' })
		closeVerificationEmailDialog()
	}

	const sendForgotPasswordEmail = async (email:string) => {
		try {
			setSnackBarObject({ shoudlShow: true, message: 'Password reset email sent' })
			setShouldShowforgotPasswordDialog(false)
			await sendPasswordResetEmail(auth, email)
		} catch (error) {
			setSnackBarObject({ shoudlShow: true, message: 'There was an error sending reset email.' })
		}
	}

	const sendVerificationEmail = async () => {
		setSnackBarObject({ shoudlShow: true, message: 'Verification email sent' })
		closeVerificationEmailDialog()

		try {
			await resendVerificationEmail()
		} catch (error) {
			setSnackBarObject({ shoudlShow: true, message: 'There was an error sending verification email' })
		} finally {
			auth.signOut()
		}
	}

	const localSignIn = async (userName:string, password:string) => {
		try {
			await signIn(userName, password)
		} catch (error) {
			if (error instanceof Error) {
				setErrorObject({
					shouldShow: true,
					message: error.message,
					title: 'Error Signing In',
				})
			}
		}
	}

	const navToApp = async () => {
		await Promise.all([
			loginAnalytics(),
			dispatch(getUserData()),
		])

		setSignInLOading(false)
		try {
			if (locationState?.redirectURL) {
				navigate(locationState.redirectURL)
			} else {
				navigate(dealsPage)
			}
		} catch (error) {
			if (error instanceof Error) {
				setErrorObject({
					shouldShow: true,
					message: error.message,
					title: 'Error Retrieving Data',
				})
			}
		}
	}

	return (
		<Div sx={styles.root}>
			<Paper elevation={3} sx={styles.paper}>
				<Login
					logo={logo}
					loading={signInLoading}
					showforgotPasswordDialog={showforgotPasswordDialog}
					signIn={localSignIn}
				/>
			</Paper>

			{shouldShowforgotPasswordDialog && (
				<ResetPasswordDialog
					closeDialogs={closeDialogs}
					sendForgotPasswordEmail={sendForgotPasswordEmail}
				/>
			)}

			{shouldShowAccountNeedsVerifiedDialog && (
				<VerificationNeededDialog
					closeDialogs={closeDialogs}
					sendVerificationEmail={sendVerificationEmail}
				/>
			)}

			{errorObject.shouldShow && (
				<BasicDialog
					shouldShow={errorObject.shouldShow}
					title={errorObject.title}
					message={errorObject.message}
					closeDialog={closeDialogs}
				/>
			)}

			{snackBarObject.shoudlShow && (
				<Snackbar
					open={snackBarObject.shoudlShow}
					message={snackBarObject.message}
					onClose={closeDialogs}
				/>
			)}
		</Div>
	)
}

export default LoginPage
