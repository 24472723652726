import { ThemeProvider } from '@mui/material/styles'
import { RealtorRewardsTheme } from '@jaymathew/ui-shared/elements/ThemeRealtorRewards'
import { ReactNode } from 'react'
import { useAppSelector } from 'redux/hooks'
import { AppState } from 'redux/reducerIndex'

interface localThemeWrapperProps {
	children: ReactNode
}

const LocalThemeWrapper = ({
	children,
}:localThemeWrapperProps) => {
	const { darkTheme } = useAppSelector((state:AppState) => state.appSettings)

	return (
		<ThemeProvider theme={RealtorRewardsTheme({ darkTheme })}>
			{children}
		</ThemeProvider>
	)
}

export default LocalThemeWrapper
