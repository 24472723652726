import { functions, storage } from '@jaymathew/ui-shared/apis/FirebaseUtilities/FirebaseConfig'
import { DocumentData, QueryDocumentSnapshot } from 'firebase/firestore'
import { httpsCallable, HttpsCallableResult } from 'firebase/functions'
import { ref, uploadBytes, UploadMetadata } from 'firebase/storage'
import Delta from 'quill-delta'
import {
	Asset, CustomFile, Deal, User
} from '../resources/types'
import * as FirebaseManager from './FirebaseManager'

// login funcs

export const loginAnalytics = async () => {
	// create the Ref to the cloud callable function.
	const cloudfunction = httpsCallable(functions, 'firebasefuncLoginAnalytics')

	// call the function with the parms
	const result: HttpsCallableResult = await cloudfunction()

	// return what the cloud function returned
	return result
}

export const getEndUserData = async () => FirebaseManager.getEndUserInfo()

export const editAccount = async (user:User, password:string) => {
	// create the Ref to the cloud callable function.
	const cloudfunction = httpsCallable(functions, 'firebasefuncEditAccount')

	// call the function with the parms
	const result: HttpsCallableResult = await cloudfunction({
		email: user.email,
		password,
		firstName: user.firstName,
		lastName: user.lastName,
		phoneNumber: user.phoneNumber,
	})

	// return what the cloud function returned
	return result
}

// tags fucns
export const getTags = async (limit?: number, searchTerm?: string, lastDocument?: QueryDocumentSnapshot<DocumentData>) => FirebaseManager.getTags(limit, searchTerm, lastDocument)

export const removeTag = async (tagID:string) => {
	// create the Ref to the cloud callable function.
	const cloudfunction = httpsCallable(functions, 'firebasefuncRemoveTag')

	// call the function with the parms
	const result: HttpsCallableResult = await cloudfunction({
		tagID,
	})

	// return what the cloud function returned
	return result
}

// deals funcs
export const getDeals = async (sortBy: keyof Deal, sort?: 'asc' | 'desc', limit?: number, searchTerm?: string, lastDocument?: QueryDocumentSnapshot<DocumentData>) => FirebaseManager.getDeals(sortBy, sort, limit, searchTerm, lastDocument)

export const updateADeal = async (dealTitle:string, dealDescription:string, tags:string[], detailHTML:Delta, dealID:string) => {
	// create the Ref to the cloud callable function.
	const cloudfunction = httpsCallable(functions, 'firebasefuncUpdateADeal')

	// call the function with the parms
	const result: HttpsCallableResult = await cloudfunction({
		dealTitle,
		dealDescription,
		tags,
		detailHTML,
		dealID,
	})

	// return what the cloud function returned
	return result.data as Deal
}

export const createADeal = async (dealTitle:string, dealDescription:string, tags:string[], detailHTML:Delta) => {
	// create the Ref to the cloud callable function.
	const cloudfunction = httpsCallable(functions, 'firebasefuncCreateADeal')

	// call the function with the parms
	const result: HttpsCallableResult = await cloudfunction({
		dealTitle,
		dealDescription,
		tags,
		detailHTML,
	})

	// return what the cloud function returned
	return result.data as Deal
}

export const getDealDetail = async (dealID:string) => FirebaseManager.getDealDetail(dealID)

// image funcs
export const getAssets = async (sortBy: keyof Deal, sort?: 'asc' | 'desc', limit?: number, searchTerm?: string, lastDocument?: QueryDocumentSnapshot<DocumentData>) => FirebaseManager.getAssets(sortBy, sort, limit, searchTerm, lastDocument)

const createAsset = async (userUID:string, tags?:string[]) => FirebaseManager.createAsset(userUID, tags)

export const deleteAsset = async (asset:Asset) => FirebaseManager.deleteAsset(asset)

export const saveAsset = async (photo:CustomFile | undefined, userUID:string, tags?:string[]) => {
	const newAsset = await createAsset(userUID, tags)

	if (photo) {
		// Points to this project's own image folder
		const imageFolderRef = ref(storage, `assetTemp/${photo.file!.name}`)

		// Create file metadata including the content type
		const metadata:UploadMetadata = {
			customMetadata: {
				assetID: newAsset.nodeName!,
				userToken: userUID,
			},
		}

		await uploadBytes(imageFolderRef, photo.file!, metadata)
	}

	return newAsset
}
