import { configureStore } from '@reduxjs/toolkit'
import {
	// FLUSH, PAUSE,
	// PERSIST, PURGE,
	// REGISTER, REHYDRATE,
	persistReducer, persistStore
} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import rootReducer from './reducerIndex'

const persistConfig = {
	key: 'root',
	version: 1,
	storage,
}

const persistedStore = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
	reducer: persistedStore,
	middleware: (getDefaultMiddleware) => getDefaultMiddleware({
		serializableCheck: false,
		// {
		// 	ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
		// },
	}),
})

export const persistor = persistStore(store)

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
