import React, { StrictMode } from 'react'
import ReactDOM from 'react-dom/client'
import '@jaymathew/ui-shared/apis/FirebaseUtilities/FirebaseConfig'
import Routes from 'routes/routes'

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
	<StrictMode>
		<Routes />
	</StrictMode>,
)
