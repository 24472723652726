import { combineReducers } from 'redux'
import appSettings, { AppSettingsState } from './appSettingsSlice'
import user, { userState } from './userSlice'

export interface AppState {
	appSettings: AppSettingsState
	user: userState
}

const rootReducer = combineReducers({
	appSettings,
	user,
})

export default rootReducer
