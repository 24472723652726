import { auth, storage } from '@jaymathew/ui-shared/apis/FirebaseUtilities/FirebaseConfig'
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { editAccount, getEndUserData } from 'api/NetworkManager'
import { ref, uploadBytes, UploadMetadata } from 'firebase/storage'
import { CustomFile, User } from 'resources/types'
import { userSignOut } from './globalActions'

export const getUserData = createAsyncThunk('user/getUserData', async () => {
	const userData = <User> await getEndUserData()
	const { currentUser } = auth
	const userClaims = (await currentUser!.getIdTokenResult()).claims

	const newUser = {
		...userData,
		...userClaims as object,
	}

	return newUser
})

export const editUserAccount = createAsyncThunk('user/editUserProfile', async ({ user, password, profilePhoto }:{ user?: User, password: string, profilePhoto?: CustomFile }) => {
	if (!user) throw new Error('No user is logged in')
	await editAccount(user, password)

	if (profilePhoto && profilePhoto.image && !profilePhoto.image.includes('googleapis.com')) {
		// there is a projectPhotoFile so save it

		// Points to this project's own image folder
		const userProfilePhotoFolderRef = ref(storage, `userProfilePhotoTemp/${user.fullName}ProfilePhoto`)

		// Create file metadata including the content type
		const metadata: UploadMetadata = {
			customMetadata: {
				userToken: user.uid!,
			},
		}

		// let blob = await fetch(profilePhoto.image).then(r => r.blob());
		await uploadBytes(userProfilePhotoFolderRef, profilePhoto.file!, metadata)

		if (profilePhoto && profilePhoto.image) {
			user.photoURL = profilePhoto.image
		}
	}

	return user
})

export interface userState {
	currentUser?: User
	currentUserPhotoURL?: CustomFile
}

const initialState: userState = {
	currentUser: undefined,
	currentUserPhotoURL: undefined,
}

export const userSlice = createSlice({
	name: 'user',
	initialState,
	reducers: {
		assignProfilePhoto: (state, action: PayloadAction<CustomFile>) => {
			state.currentUserPhotoURL = action.payload
			if (state.currentUser && state.currentUser.photoURL) state.currentUser.photoURL = action.payload.image
		},
	},
	extraReducers: (builder) => {
		builder.addCase(getUserData.rejected, (state) => {
			state.currentUser = undefined
		})
		builder.addCase(getUserData.fulfilled, (state, action) => {
			state.currentUser = action.payload
		})
		builder.addCase(editUserAccount.fulfilled, (state, action) => {
			state.currentUser = action.payload
		})
		builder.addCase(userSignOut, (state) => {
			state.currentUser = undefined
		})
	},
})

export const { assignProfilePhoto } = userSlice.actions

export default userSlice.reducer
