import { Stack } from '@mui/material'
import { SxProps, Theme } from '@mui/material/styles'
import TextField from '@mui/material/TextField'
import { Img } from '@jaymathew/ui-shared/elements/Img'
import { PasswordTextField } from '@jaymathew/ui-shared/elements/PasswordTextField'
import { ProgressButton as Button } from '@jaymathew/ui-shared/elements/ProgressButton'
import React, { FC, useState } from 'react'

const styles = {

	logoStyle: {
		margin: 2,
		height: { xs: 50, lg: 75 },
	} as SxProps<Theme>,

	textFieldStyle: {
		mt: 1,
		mb: 1,
		width: { xs: 280, sm: 380 },
	} as SxProps<Theme>,

}

export interface LoginProps {
	logo: string
	loading?: boolean
	username?: string
	password?: string
	showforgotPasswordDialog: () => void
	signIn: (username: string, password: string) => Promise<void>
}

const Login: FC<LoginProps> = ({
	logo,
	loading,
	username,
	password,
	showforgotPasswordDialog,
	signIn,
}) => {
	const [userData, setUserData] = useState({ username: username || '', password: password || '' })
	const updateTextFields = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
		setUserData((prevState) => ({ ...prevState, [event.target.id]: event.target.value }))
	}

	const interalSignIn = () => {
		signIn(userData.username, userData.password)
	}

	return (
		<Stack alignItems='center'>
			<a href={`https://${process.env.REACT_APP_DOMAIN}`}>
				<Img src={logo} alt='logo' sx={styles.logoStyle} />
			</a>
			<TextField
				fullWidth
				id='username'
				label='Email'
				type='username'
				margin='normal'
				sx={styles.textFieldStyle}
				onChange={updateTextFields}
				variant='outlined'
				autoComplete='username'
				value={userData.username}
			/>
			<PasswordTextField
				fullWidth
				didPressEnter={interalSignIn}
				onChange={updateTextFields}
				id='password'
				label='Password'
				type='password'
				sx={styles.textFieldStyle}
				autoComplete='current-password'
				value={userData.password}
			/>

			<Button
				onClick={interalSignIn}
				size='large'
				sx={{
					height: 45,
					width: '100%',
				}}
				processing={loading}
				processingTitle='Signing In'
			>
				SignIn
			</Button>

			<Button
				onClick={showforgotPasswordDialog}
				variant='text'
			>
				Forgot Password
			</Button>

		</Stack>
	)
}

export default Login
